import { fetchUtils } from 'ra-core';

// ///////////////////////////////////////////
// Based on npm package ra-data-json-server //
// ///////////////////////////////////////////


/**
 * Maps react-admin queries to a json-server powered REST API
 *
 * @see https://github.com/typicode/json-server
 *
 * @example
 *
 * getList          => GET http://my.api.url/posts?_sort=title&_order=ASC&_start=0&_end=24
 * getOne           => GET http://my.api.url/posts/123
 * getManyReference => GET http://my.api.url/posts?author_id=345
 * getMany          => GET http://my.api.url/posts?id=123&id=456&id=789
 * create           => POST http://my.api.url/posts/123
 * update           => PUT http://my.api.url/posts/123
 * updateMany       => PUT http://my.api.url/posts/123, PUT http://my.api.url/posts/456, PUT http://my.api.url/posts/789
 * delete           => DELETE http://my.api.url/posts/123
 *
 * @example
 *
 * import * as React from "react";
 * import { Admin, Resource } from 'react-admin';
 * import jsonServerProvider from 'ra-data-json-server';
 *
 * import { PostList } from './posts';
 *
 * const App = () => (
 *     <Admin dataProvider={jsonServerProvider('http://jsonplaceholder.typicode.com')}>
 *         <Resource name="posts" list={PostList} />
 *     </Admin>
 * );
 *
 * export default App;
 */

// /////////////////////////////////////////////////////////////////////////////////////////////////
// react-admin Data Provider                                                                      //
//                                                                                                //
// Request Format:                                                                                //
// | Methode          | Usage                                           | Implementation   |      //
// |------------------|-------------------------------------------------|------------------|      //
// | getList          | Search for resources                            | done             |      //
// | getOne           | Read a single resource, by id                   | done             |      //
// | getMany          | Read a list of resource, by ids                 | nope             |      //
// | getManyReference | Read a list of resources related to another one | nope, not yet    |      //
// | create           | Create a single resource                        | done             |      //
// | update           | Update a single resource                        | done             |      //
// | updateMany       | Update multiple resources                       | not used         |      //
// | delete           | Delete a single resource                        | not used         |      //
// | deleteMany       | Delete multiple resources                       | not used         |      //
//                                                                                                //
//                                                                                                //
// /////////////////////////////////////////////////////////////////////////////////////////////////




export default (apiUrl, httpClient = fetchUtils.fetchJson) => ({
	
	// ///////////////////////////////////////////////////////////////////////////////////////////////
	// request format for getList:                                                                  //
	// { pagination: { page: {int} , perPage: {int} }, sort: { field: {string}, order: {string} }, filter: {Object}, meta: {Object} }
	//                                                                                              //
	// expected format by ppAPI:                                                                    //
	// { ...otherArgs, "pagination": {"page": {int|1 default}, "set": {int| 25 default} } }         //
	//                                                                                              //
	// response expected by react-admin:                                                            //
	// { data: {Record[]}, total: {int} } // WIP v4.0.0 optional: pageInfo{ hasPreviousPage:{bool}, hasNextPage: {bool} }
	// ///////////////////////////////////////////////////////////////////////////////////////////////
	getList: (resource, params) => {
		const { page, perPage } = params.pagination;
		const { field, order } = params.sort;
		const { filter } = params; // WIP v4.0.0 should be filters Obj
		// console.log(`%c params to getList for ${resource}: `, 'background: #222; color: #bada55', params, );
		console.log(`%c ### CALL TO GET_LIST ### \r\n resource: ${resource} params: `, 'background: #c9c9F9; color: #51a', params)
		const searchStr = filter?.fulltextsearch || "";
		// after usage of the fulltextsearch drop it from params
		delete filter?.fulltextsearch

		// console.log("filter: " + JSON.stringify(filter));
		// set a filter for each filter element
		const generatedFilters = {}
		for ( const f in filter) {
			// console.log("f in filter", f)
			// console.log("f.value: " + filter[f]);
			// check of key is prefixed as exists_[propname]
			// if it is run switch to set type: equals true/false or type: exists false to run a nullableboolean filter.
			if (f.startsWith("exists_")) {
				// console.log('start with exists_')
				const fieldNameWithoutPrefix = f.substring(7);
				if(filter[f] === "true") {
					generatedFilters[fieldNameWithoutPrefix] = [{ "type": "equal", "value": true}]
				} else if (filter[f] === "false") {
					generatedFilters[fieldNameWithoutPrefix] = [{ "type": "equal", "value": false}]
				} else if (filter[f] === "undefined") {
					generatedFilters[fieldNameWithoutPrefix] = [{ "type": "exist", "value": false}]
				}
			}
			// check of key is prefixed as like_[propname]
			else if (f.startsWith("like_")) {
				// console.log('start with like_')
				const fieldNameWithoutPrefix = f.substring(5);
				generatedFilters[fieldNameWithoutPrefix] = [{ "type": "like", "value": filter[f] }];
			}
			// subtype of properties
			else if (f === "properties") {
				// console.log("PROPERTIE LOOP" + JSON.stringify(filter[f]))
				generatedFilters['properties'] = {};
				for ( const p in filter[f]) {
					// console.log("p in filter", p)
					// console.log("p.value: " + filter[f][p]);
					generatedFilters[f][p] = [{ "type": "equal", "value": filter[f][p] }]
				}
			} else {
				generatedFilters[f] = [{ "type": "equal", "value": filter[f] }]
			}
		}
		console.log("generatedFilters: " + JSON.stringify(generatedFilters));

		// check if there is a filter element from RA
		// if params?.filter
		const query = {
			editable: true, // only needed for backend content that should not be transformed by the api before the return
			// ...fetchUtils.flattenObject(params.filter),
			// search : search_str, // regex on defined fields
			...( searchStr ? { search: searchStr } : {}),
			...( generatedFilters ? { filters: generatedFilters } : {}),
			// filter: {
			// author_id: id,
			// color: blue,
			// ...
			// },
			pagination: {
				field: field,
				order: order,
				page: page,
				set: perPage,
			}
		};

		let queryRessource = resource === 'settings' ? 'configs' : resource;

		console.log(`%c query to getList endpoint from ${queryRessource}: `, 'background: #222; color: #bada55', query, );
		return httpClient(`${apiUrl}/${queryRessource}/list`, {
			method: 'POST',
			body: JSON.stringify(query),
		}).then(({ json }) => {
			// got an error-msg in api response
			if (json?.errors) {
				console.log(`%c json response from httpClient for GET_LIST via resource ${resource}: `, 'background: #F9c9c9; color: #a15', json);
				throw new Error(json?.errors[0]?.message);
			} else {
				console.log(`%c json response from httpClient for GET_LIST via resource ${resource}: `, 'background: #D9D9D9; color: #1a5', json);
				return ({
					data: json?.data ?? [],
					total: json?.pagination?.total ?? 0
				})
			}
		})
	},



	// ///////////////////////////////////////////////////////////////////////////////////////////////
	// request format for getOne:                                                                   //
	// { "id": string // MongoID, "meta": {Obj} // optional }                                       //
	//                                                                                              //
	// expected format by ppAPI:                                                                    //
	// { "id": "12-bit BSON ObjectID" }                                                             //
	//                                                                                              //
	// response expected by react-admin:                                                            //
	// { data: {Record} }                                                                           //
	//                                                                                              //
	// removes the plural "s" from resource name to reflect ppAPI's needs                           //
	// ///////////////////////////////////////////////////////////////////////////////////////////////
	getOne: (resource, params) => {
		// make resource name singular
		const lastCharPos = resource.length - 1;
		const lastChar = resource.charAt(lastCharPos);
		let resource_name = resource;
		(lastChar === "s") ? resource_name = resource.substring(0, lastCharPos) : '';
		console.log(`%c ### CALL TO GET_ONE ### \r\n resource_name: ${resource_name} ID: ${params.id}`, 'background: #c9c9F9; color: #51a')

		const query = {
			id: params.id,
			editable: true // only needed for backend content that should not be transformed by the api before the return
		};

		return httpClient(`${apiUrl}/${resource_name}/show`, {
			method: 'POST',
			body: JSON.stringify(query),
		})
		.then(({ json }) => {
			// got an error-msg in api response
			if (json?.errors) {
				console.log(`%c json response from httpClient for GET_ONE via resource ${resource_name}: `, 'background: #F9c9c9; color: #a15', json);
				throw new Error(json?.errors[0]?.message);
			} else {
				console.log(`%c json response from httpClient for GET_ONE via resource ${resource_name}: `, 'background: #D9D9D9; color: #1a5', json);
				return ({
					data: json?.data ?? {}
				})
			}
		})
	},



	// ///////////////////////////////////////////////////////////////////////////////////////////////
	// request format for UPDATE_ONE:                                                               //
	// {                                                                                            //
	// 	"id": string // MongoID,                                                                    //
	// 	"data": {Obj} // all the new data as an multidimensional Obj                                //
	// 	"previousData": {Obj} // all the old unchanged data as an multidimensional Obj              //
	// 	"meta": {Obj} // optional                                                                   //
	// }                                                                                            //
	//                                                                                              //
	// expected format by ppAPI:                                                                    //
	// {                                                                                            //
	// 	"id": "12-bit BSON ObjectID",                                                               //
	// 	"set": {Obj} // update all the existing object.keys with the delivered value                //
	// 	"unset": Array[] // array of collection key to remove from record                           //
	// 	}                                                                                           //
	//                                                                                              //
	// response expected by react-admin:                                                            //
	// { data: {Record} }                                                                           //
	//                                                                                              //
	// removes the plural "s" from resource name to reflect ppAPI's needs                           //
	// ///////////////////////////////////////////////////////////////////////////////////////////////
	update: (resource, params) => {
		// make resource name singular
		const lastCharPos = resource.length - 1;
		const lastChar = resource.charAt(lastCharPos);
		let resource_name = resource;
		(lastChar === "s") ? resource_name = resource.substring(0, lastCharPos) : '';
		if (!params.id)
			throw Error("No ID found in query.")
		console.log(`%c ### CALL TO UPDATE_ONE ### \r\n resource_name: ${resource_name} ID: ${params.id} params: `, 'background: #c9c9F9; color: #51a', params)

		// console.log("resource name and ID to updateOne: " + resource_name + " / " + params.id);

		// console.log("params updateOne: ", params);

		const inputData = params.data;
		const previousData = params.previousData;

		// remove entryHistory from obj before generating set/unset data
		if (Object.hasOwn(inputData, 'entryHistory')) {
			// console.log("deleting history obj from newData: ", inputData.entryHistory);
			delete inputData.entryHistory;
		}
		if (typeof previousData === 'object' && previousData !== null) {
			if (Object.hasOwn(previousData, 'entryHistory')) {
				// console.log("deleting history obj from previousData: ", previousData.entryHistory);
				delete previousData.entryHistory;
			}
		}

		// if key exists in newData and is === in oldData no changes -> dont push into setData, drop from oldData
		// if key exists in newData and is not set in oldData -> push to setData
		// if keys remain in oldData and is not in newData push to unsetData
		let generateData = (newData, oldData) => {
			let setDataObj = {};

			for (let key in newData) {
				// console.log("key: " , key);
				// if (typeof newData[key] === 'object' && newData[key] !== null && newData[key] !== undefined) {
				if ((typeof newData[key] === 'object' && !Array.isArray(newData[key])) && newData[key] !== null && newData[key] !== undefined && !(newData[key] instanceof Date)) {
					let objectData = generateData(newData[key], oldData[key] ?? {});
					setDataObj[key] = objectData[0];
					(objectData[1] === false) ? delete oldData[key] : oldData[key] = objectData[1];
				} else {
					if( (key in oldData && oldData[key] !== newData[key]) || !(key in oldData) ) {
						// remove null and undefined values before they are set
						if(newData[key] === '' || newData[key] === null || newData[key] === undefined) {
							console.log("empty value: ", key);
						} else if ( newData[key] instanceof Date) {
							setDataObj[key] = newData[key].toISOString();
						} else {
							setDataObj[key] = newData[key];
						}
					}
					if (key in oldData) {
						// if newData values was null / '' or undefined leave oldData key intact
						if(!(newData[key] === '' || newData[key] === null || newData[key] === undefined))
							delete oldData[key];
					}
				}
			}
			let stillHasProperties = Object.getOwnPropertyNames(oldData)

			return [setDataObj, stillHasProperties.length > 0 ? oldData : false];
		};

		let [ setDataObj, unsetDataObj ] = generateData(inputData, previousData);

		// check for preflight dry-run
		// let preflight = false;
		// (params?.preflight === true) ? preflight = true : "";
		const query = {
			id: params.id, // the mongoID to update
			// preflight: preflight, // tells API if to actually write the data to db.
			set: setDataObj, // an object of values to change
			unset: unsetDataObj // an obj of values to unset because they're null / undefined or ''
		};
		console.log(`%c query to update endpoint from ${resource}: `, 'background: #222; color: #bada55', query, );
		// console.log("query for UPDATE_ONE: ",query);

		return httpClient(`${apiUrl}/${resource_name}/edit`, {
			method: 'POST',
			body: JSON.stringify(query),
		})
		.then(({ json }) => {
			// got an error-msg in api response
			if (json?.errors) {
				console.log(`%c json response from httpClient for UPDATE_ONE via resource ${resource_name}: `, 'background: #F9c9c9; color: #a15', json);
				throw new Error(json?.errors[0]?.message);
			} else {
				console.log(`%c json response from httpClient for UPDATE_ONE via resource ${resource_name}: `, 'background: #D9D9D9; color: #1a5', json);
				return ({
					data: json?.data ?? {}
				})
			}
		})
	},



	// ///////////////////////////////////////////////////////////////////////////////////////////////
	// request format for CREATE:                                                                   //
	// {                                                                                            //
	// 	"data": {Obj} // all the new data as an multidimensional Obj                                //
	// 	"meta": {Obj} // optional                                                                   //
	// }                                                                                            //
	//                                                                                              //
	// expected format by ppAPI:                                                                    //
	// {                                                                                            //
	// 	"type": string // required,                                                                 //
	// 	... // more key/values pair can be transmitted but they are not required,                   //
	//      // if they are given, the must past the validtator checks from the given "type"         //
	// 	}                                                                                           //
	//                                                                                              //
	// response expected by react-admin:                                                            //
	// { data: {Record} }                                                                           //
	//                                                                                              //
	// removes the plural "s" from resource name to reflect ppAPI's needs                           //
	// ///////////////////////////////////////////////////////////////////////////////////////////////
	create: (resource, params) => {
		if (resource === "media") {
			// if we are a media create query
			let formData = new FormData();
			formData.append("data", params.data.image.rawFile);


			return httpClient(`${apiUrl}/media/upload`, {
				method: 'POST',
				body: formData,
			})
			.then(({ json }) => {
				// got an error-msg in api response
				if (json?.data?.failedFiles?.length) {
					console.log(`%c json response from httpClient for CREATE via resource MEDIA: `, 'background: #F9c9c9; color: #a15', json);
					throw new Error("Die Datei konnte nicht hochgeladen werden.");
				} else {
					console.log(`%c json response from httpClient for CREATE via resource MEDIA: `, 'background: #D9D9D9; color: #1a5', json);
					let data = {
						id: json?.data?.uploadedFiles[0].id ?? "",
						originalName: json?.data?.uploadedFiles[0].originalName ?? ""
					}
					console.log("data: " + JSON.stringify(data));
					return ({data});
				}
			})
		}


		// make resource name singular
		const lastCharPos = resource.length - 1;
		const lastChar = resource.charAt(lastCharPos);
		let resource_name = resource;
		(lastChar === "s") ? resource_name = resource.substring(0, lastCharPos) : '';
		console.log("resource name and params for create: " + resource_name + " / " + JSON.stringify(params));

		// remove emptyStrings on base level and within properties
		for ( const d in params.data ){
			(params.data[d] === "") ? delete params.data[d] : null;
			if (d === "properties") {
				// console.log('is properties')
				for ( const d in params.data.properties )
					(params.data.properties[d] === "") ? delete params.data.properties[d] : null;
			}
		}

		const query = {
			...params.data
		};

		return httpClient(`${apiUrl}/${resource_name}/create`, {
			method: 'POST',
			body: JSON.stringify(query),
		})
		.then(({ json }) => {
			// got an error-msg in api response
			if (json?.errors) {
				console.log(`%c json response from httpClient for CREATE via resource ${resource_name}: `, 'background: #F9c9c9; color: #a15', json);
				throw new Error(json?.errors[0]?.message);
			} else {
				console.log(`%c json response from httpClient for CREATE via resource ${resource_name}: `, 'background: #D9D9D9; color: #1a5', json);
				return ({
					data: json?.data ?? {}
				})
			}
		})
	},







	// ///////////////////////////////////////////////////////////////////////////////////////////////
	// default																																											//
	// ///////////////////////////////////////////////////////////////////////////////////////////////
	// getList: (resource, params) => {
	//     const { page, perPage } = params.pagination;
	//     const { field, order } = params.sort;
	//     const query = {
	//         ...fetchUtils.flattenObject(params.filter),
	//         _sort: field,
	//         _order: order,
	//         _start: (page - 1) * perPage,
	//         _end: page * perPage,
	//     };
	//     const url = `${apiUrl}/${resource}?${stringify(query)}`;
	//     return httpClient(url).then(({ headers, json }) => {
	//         if (!headers.has('x-total-count')) {
	//             throw new Error(
	//                 'The X-Total-Count header is missing in the HTTP Response. The jsonServer Data Provider expects responses for lists of resources to contain this header with the total number of results to build the pagination. If you are using CORS, did you declare X-Total-Count in the Access-Control-Expose-Headers header?'
	//             );
	//         }
	//         return {
	//             data: json,
	//             total: parseInt(
	//                 headers.get('x-total-count').split('/').pop(),
	//                 10
	//             ),
	//         };
	//     });
	// },
	// getOne: (resource, params) =>
	// 	httpClient(`${apiUrl}/${resource}/${params.id}`).then(({ json }) => ({
	// 		data: json,
	// 	})),
	// update: (resource, params) =>
	// 	httpClient(`${apiUrl}/${resource}/${params.id}`, {
	// 		method: 'PUT',
	// 		body: JSON.stringify(params.data),
	// 	}).then(({ json }) => ({ data: json })),
	// create: (resource, params) =>
	// 	httpClient(`${apiUrl}/${resource}`, {
	// 		method: 'POST',
	// 		body: JSON.stringify(params.data),
	// 	}).then(({ json }) => ({
	// 		data: { ...params.data, id: json.id },
	// 	})),

// /////////////////////////////////////////////////////////////////////////////////////////////////
// no implementation need yet                                                                     //
// /////////////////////////////////////////////////////////////////////////////////////////////////
	// getMany: (resource, params) => {
	// 	const query = {
	// 		id: params.ids,
	// 	};
	// 	const url = `${apiUrl}/${resource}?${stringify(query)}`;
	// 	return httpClient(url).then(({ json }) => ({ data: json }));
	// },


// /////////////////////////////////////////////////////////////////////////////////////////////////
// no implementaion need yet                                                                      //
// /////////////////////////////////////////////////////////////////////////////////////////////////
	// getManyReference: (resource, params) => {
	// 	const { page, perPage } = params.pagination;
	// 	const { field, order } = params.sort;
	// 	const query = {
	// 		...fetchUtils.flattenObject(params.filter),
	// 		[params.target]: params.id,
	// 		_sort: field,
	// 		_order: order,
	// 		_start: (page - 1) * perPage,
	// 		_end: page * perPage,
	// 	};
	// 	const url = `${apiUrl}/${resource}?${stringify(query)}`;

	// 	return httpClient(url).then(({ headers, json }) => {
	// 		if (!headers.has('x-total-count')) {
	// 			throw new Error(
	// 				'The X-Total-Count header is missing in the HTTP Response. The jsonServer Data Provider expects responses for lists of resources to contain this header with the total number of results to build the pagination. If you are using CORS, did you declare X-Total-Count in the Access-Control-Expose-Headers header?'
	// 			);
	// 		}
	// 		return {
	// 			data: json,
	// 			total: parseInt(
	// 				headers.get('x-total-count').split('/').pop(),
	// 				10
	// 			),
	// 		};
	// 	});
	// },

	// ///////////////////////////////////////////////////////////////////////////////////////////////
	// there is no updated many in our RA yet                                                       //
	// ///////////////////////////////////////////////////////////////////////////////////////////////
	// json-server doesn't handle filters on UPDATE route, so we fallback to calling UPDATE n times instead
	// updateMany: (resource, params) =>
	// 	Promise.all(
	// 		params.ids.map(id =>
	// 			httpClient(`${apiUrl}/${resource}/${id}`, {
	// 				method: 'PUT',
	// 				body: JSON.stringify(params.data),
	// 			})
	// 		)
	// 	).then(responses => ({ data: responses.map(({ json }) => json.id) })),

	// ///////////////////////////////////////////////////////////////////////////////////////////////
	// we should never delete anything                                                              //
	// ///////////////////////////////////////////////////////////////////////////////////////////////
	// delete: (resource, params) =>
	// 	httpClient(`${apiUrl}/${resource}/${params.id}`, {
	// 		method: 'DELETE',
	// 	}).then(({ json }) => ({ data: json })),

	// ///////////////////////////////////////////////////////////////////////////////////////////////
	// we should never delete anything                                                              //
	// ///////////////////////////////////////////////////////////////////////////////////////////////
	// json-server doesn't handle filters on DELETE route, so we fallback to calling DELETE n times instead
	// deleteMany: (resource, params) =>
	// 	Promise.all(
	// 		params.ids.map(id =>
	// 			httpClient(`${apiUrl}/${resource}/${id}`, {
	// 				method: 'DELETE',
	// 			})
	// 		)
	// 	).then(responses => ({ data: responses.map(({ json }) => json.id) })),







	// /////////////////////////////////////////////////////////////////////////////////////////////////
	// custom routes for articles                                                                     //
	// /////////////////////////////////////////////////////////////////////////////////////////////////
	publishArticle: (id) => {
		console.log("Publish this Article ID: ", JSON.stringify(id))
		return httpClient(`${apiUrl}/article/publish`, {
			method: 'POST',
			body: JSON.stringify({id}),
		})
		.then(({ json }) => {
			// got an error-msg in api response
			if (json?.errors) {
				console.log("%c json response from httpClient for PUBLISH_ARTICLE via resource: ", 'background: #F9c9c9; color: #a15', json);
				throw new Error( (json?.errors[1]) ? json?.errors[1].code : json?.errors[0]?.code );
			} else {
				console.log("%c json response from httpClient for PUBLISH_ARTICLE via resource: ", 'background: #D9D9D9; color: #1a5', json);
				return ({
					data: json?.data ?? {}
				})
			}
		})
	},
	unpublishArticle: (id) => {
		console.log("Unpublish this Article ID: ", JSON.stringify(id))
		return httpClient(`${apiUrl}/article/unpublish`, {
			method: 'POST',
			body: JSON.stringify({id}),
		})
		.then(({ json }) => {
			// got an error-msg in api response
			if (json?.errors) {
				console.log("%c json response from httpClient for UNPUBLISH_ARTICLE via resource: ", 'background: #F9c9c9; color: #a15', json);
				throw new Error( (json?.errors[1]) ? json?.errors[1]?.code : json?.errors[0]?.code );
			} else {
				console.log("%c json response from httpClient for UNPUBLISH_ARTICLE via resource: ", 'background: #D9D9D9; color: #1a5', json);
				return ({
					data: json?.data ?? {}
				})
			}
		})
	},









	// /////////////////////////////////////////////////////////////////////////////////////////////////
	// custom routes for orders                                                                       //
	// /////////////////////////////////////////////////////////////////////////////////////////////////
	rejectOrder: (id) => {
		console.log("Reject this Order ID: ", JSON.stringify(id))
		return httpClient(`${apiUrl}/order/reject`, {
			method: 'POST',
			body: JSON.stringify({id}),
		})
		.then(({ json }) => {
			// got an error-msg in api response
			if (json?.errors) {
				console.log("%c json response from httpClient for REJECT_ORDER via resource: ", 'background: #F9c9c9; color: #a15', json);
				throw new Error( (json?.errors[1]) ? json?.errors[1].code : json?.errors[0]?.code );
			} else {
				console.log("%c json response from httpClient for REJECT_ORDER via resource: ", 'background: #D9D9D9; color: #1a5', json);
				return ({
					data: json?.data ?? {}
				})
			}
		})
	},
	acceptOrder: (id) => {
		console.log("Accept this Order ID: ", JSON.stringify(id))
		return httpClient(`${apiUrl}/order/accept`, {
			method: 'POST',
			body: JSON.stringify({id}),
		})
		.then(({ json }) => {
			// got an error-msg in api response
			if (json?.errors) {
				console.log("%c json response from httpClient for ACCEPT_ORDER via resource: ", 'background: #F9c9c9; color: #a15', json);
				throw new Error( (json?.errors[1]) ? json?.errors[1].code : json?.errors[0]?.code );
			} else {
				console.log("%c json response from httpClient for ACCEPT_ORDER via resource: ", 'background: #D9D9D9; color: #1a5', json);
				return ({
					data: json?.data ?? {}
				})
			}
		})
	},
	cancelOrder: (id) => {
		console.log("Cancel this Order ID: ", JSON.stringify(id))
		return httpClient(`${apiUrl}/order/cancel`, {
			method: 'POST',
			body: JSON.stringify({id}),
		})
		.then(({ json }) => {
			// got an error-msg in api response
			if (json?.errors) {
				console.log("%c json response from httpClient for CANCEL_ORDER via resource: ", 'background: #F9c9c9; color: #a15', json);
				throw new Error( (json?.errors[1]) ? json?.errors[1].code : json?.errors[0]?.code );
			} else {
				console.log("%c json response from httpClient for CANCEL_ORDER via resource: ", 'background: #D9D9D9; color: #1a5', json);
				return ({
					data: json?.data ?? {}
				})
			}
		})
	},
	paidOrder: (id) => {
		console.log("Set this Order ID to paid: ", JSON.stringify(id))
		return httpClient(`${apiUrl}/order/paid`, {
			method: 'POST',
			body: JSON.stringify({id}),
		})
		.then(({ json }) => {
			// got an error-msg in api response
			if (json?.errors) {
				console.log("%c json response from httpClient for PAID_ORDER via resource: ", 'background: #F9c9c9; color: #a15', json);
				throw new Error( (json?.errors[1]) ? json?.errors[1].code : json?.errors[0]?.code );
			} else {
				console.log("%c json response from httpClient for PAID_ORDER via resource: ", 'background: #D9D9D9; color: #1a5', json);
				return ({
					data: json?.data ?? {}
				})
			}
		})
	},








	// /////////////////////////////////////////////////////////////////////////////////////////////////
	// custom routes for remarks                                                                     //
	// /////////////////////////////////////////////////////////////////////////////////////////////////
	publishRemark: (id) => {
		console.log("Publish this Remark ID: ", JSON.stringify(id))
		return httpClient(`${apiUrl}/remark/publish`, {
			method: 'POST',
			body: JSON.stringify({id}),
		})
		.then(({ json }) => {
			// got an error-msg in api response
			if (json?.errors) {
				console.log("%c json response from httpClient for PUBLISH_REMARK via resource: ", 'background: #F9c9c9; color: #a15', json);
				throw new Error( (json?.errors[1]) ? json?.errors[1].code : json?.errors[0]?.code );
			} else {
				console.log("%c json response from httpClient for PUBLISH_REMARK via resource: ", 'background: #D9D9D9; color: #1a5', json);
				return ({
					data: json?.data ?? {}
				})
			}
		})
	},
	unpublishRemark: (id) => {
		console.log("Unpublish this Remark ID: ", JSON.stringify(id))
		return httpClient(`${apiUrl}/remark/unpublish`, {
			method: 'POST',
			body: JSON.stringify({id}),
		})
		.then(({ json }) => {
			// got an error-msg in api response
			if (json?.errors) {
				console.log("%c json response from httpClient for UNPUBLISH_REMARK via resource: ", 'background: #F9c9c9; color: #a15', json);
				throw new Error( (json?.errors[1]) ? json?.errors[1]?.code : json?.errors[0]?.code );
			} else {
				console.log("%c json response from httpClient for UNPUBLISH_REMARK via resource: ", 'background: #D9D9D9; color: #1a5', json);
				return ({
					data: json?.data ?? {}
				})
			}
		})
	},
	deleteRemark: (id) => {
		console.log("Delete this Remark ID: ", JSON.stringify(id))
		return httpClient(`${apiUrl}/remark/delete`, {
			method: 'POST',
			body: JSON.stringify({id}),
		})
		.then(({ json }) => {
			// got an error-msg in api response
			if (json?.errors) {
				console.log("%c json response from httpClient for DELETE_REMARK via resource: ", 'background: #F9c9c9; color: #a15', json);
				throw new Error( (json?.errors[1]) ? json?.errors[1]?.code : json?.errors[0]?.code );
			} else {
				console.log("%c json response from httpClient for DELETE_REMARK via resource: ", 'background: #D9D9D9; color: #1a5', json);
				return ({
					data: json?.data ?? {}
				})
			}
		})
	},








	// /////////////////////////////////////////////////////////////////////////////////////////////////
	// custom routes for products                                                                     //
	// /////////////////////////////////////////////////////////////////////////////////////////////////
	publishProduct: (id) => {
		console.log("Publish this Product ID: ", JSON.stringify(id))
		return httpClient(`${apiUrl}/product/publish`, {
			method: 'POST',
			body: JSON.stringify({id}),
		})
		.then(({ json }) => {
			// got an error-msg in api response
			if (json?.errors) {
				console.log("%c json response from httpClient for PUBLISH_PRODUCT via resource: ", 'background: #F9c9c9; color: #a15', json);
				throw new Error( (json?.errors[1]) ? json?.errors[1].code : json?.errors[0]?.message );
			} else {
				console.log("%c json response from httpClient for PUBLISH_PRODUCT via resource: ", 'background: #D9D9D9; color: #1a5', json);
				return ({
					data: json?.data ?? {}
				})
			}
		})
	},
	unpublishProduct: (id) => {
		console.log("Unpublish this Product ID: ", JSON.stringify(id))
		return httpClient(`${apiUrl}/product/unpublish`, {
			method: 'POST',
			body: JSON.stringify({id}),
		})
		.then(({ json }) => {
			// got an error-msg in api response
			if (json?.errors) {
				console.log("%c json response from httpClient for UNPUBLISH_PRODUCT via resource: ", 'background: #F9c9c9; color: #a15', json);
				throw new Error( (json?.errors[1]?.message) ? json?.errors[1]?.message : json?.errors[0]?.message );
			} else {
				console.log("%c json response from httpClient for UNPUBLISH_PRODUCT via resource: ", 'background: #D9D9D9; color: #1a5', json);
				return ({
					data: json?.data ?? {}
				})
			}
		})
	},
	stockupProduct: (id) => {
		console.log("Set this Product ID to inStock: ", JSON.stringify(id))
		return httpClient(`${apiUrl}/product/stockup`, {
			method: 'POST',
			body: JSON.stringify({id}),
		})
		.then(({ json }) => {
			// got an error-msg in api response
			if (json?.errors) {
				console.log("%c json response from httpClient for STOCKUP_PRODUCT via resource: ", 'background: #F9c9c9; color: #a15', json);
				throw new Error( (json?.errors[1]?.message) ? json?.errors[1]?.message : json?.errors[0]?.message );
			} else {
				console.log("%c json response from httpClient for STOCKUP_PRODUCT via resource: ", 'background: #D9D9D9; color: #1a5', json);
				return ({
					data: json?.data ?? {}
				})
			}
		})
	},
	unstockProduct: (id) => {
		console.log("Remove this Product ID from stock: ", JSON.stringify(id))
		return httpClient(`${apiUrl}/product/unstock`, {
			method: 'POST',
			body: JSON.stringify({id}),
		})
		.then(({ json }) => {
			// got an error-msg in api response
			if (json?.errors) {
				console.log("%c json response from httpClient for UNSTOCK_PRODUCT via resource: ", 'background: #F9c9c9; color: #a15', json);
				throw new Error( (json?.errors[1]?.message) ? json?.errors[1]?.message : json?.errors[0]?.message );
			} else {
				console.log("%c json response from httpClient for UNSTOCK_PRODUCT via resource: ", 'background: #D9D9D9; color: #1a5', json);
				return ({
					data: json?.data ?? {}
				})
			}
		})
	},
	deprecateProduct: (id) => {
		console.log("Deprecate this Product ID: ", JSON.stringify(id))
		return httpClient(`${apiUrl}/product/deprecate`, {
			method: 'POST',
			body: JSON.stringify({id}),
		})
		.then(({ json }) => {
			// got an error-msg in api response
			if (json?.errors) {
				console.log("%c json response from httpClient for DEPRECATE_PRODUCT via resource: ", 'background: #F9c9c9; color: #a15', json);
				throw new Error( (json?.errors[1]?.message) ? json?.errors[1]?.message : json?.errors[0]?.message );
			} else {
				console.log("%c json response from httpClient for DEPRECATE_PRODUCT via resource: ", 'background: #D9D9D9; color: #1a5', json);
				return ({
					data: json?.data ?? {}
				})
			}
		})
	},
	undeprecateProduct: (id) => {
		console.log("Undeprecate this Product ID: ", JSON.stringify(id))
		return httpClient(`${apiUrl}/product/undeprecate`, {
			method: 'POST',
			body: JSON.stringify({id}),
		})
		.then(({ json }) => {
			// got an error-msg in api response
			if (json?.errors) {
				console.log("%c json response from httpClient for UNDEPRECATE_PRODUCT via resource: ", 'background: #F9c9c9; color: #a15', json);
				throw new Error( (json?.errors[1]?.message) ? json?.errors[1]?.message : json?.errors[0]?.message );
			} else {
				console.log("%c json response from httpClient for UNDEPRECATE_PRODUCT via resource: ", 'background: #D9D9D9; color: #1a5', json);
				return ({
					data: json?.data ?? {}
				})
			}
		})
	},
});